@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Bruno+Ace+SC&family=Nunito+Sans:wght@300&family=Sigmar&family=Vina+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,100&display=swap');
.char-page{
    height: 130vh;
    width: 100vw;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), radial-gradient(60.4% 100% at 40.9% 100%, rgba(0, 179, 214, 0.5) 0%, rgba(117, 8, 184, 0) 100%), radial-gradient(98.44% 182.15% at 98.44% 100%, #F95867 0%, #7508B8 100%);
    
}
.char-heading{
    display: grid;
    place-content: center;
}
.char-heading h1{
    font-family: 'Archivo Black', sans-serif;
    color: #FFFFFF;
    font-size: 60px;
    font-weight: 400;
    text-align: center;
}
.char-heading p{
    font-family: 'Poppins', sans-serif;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 100;
    text-align: center;
    width: 60%;
    margin-left: 20%;
    margin-top: -3%;
}

/* content bottom */
.char-details-div{
    
    /* height: 100%;
    width: 100%; */
    display: flex;
    margin-top: 3%;
    justify-content: center;
    width: 90%;
    margin-left: 5%;
    align-items: center;
}
.select-img{
    height: 200%;
    width: 13%;
   
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;

   
}
.select-img img:nth-child(1){
    height: 12px;
    width:22px;
    margin-bottom: 20%;
}
.select-img img:nth-child(7){
    height: 12px;
    width:22px;
    margin-top: 13%;
}
.char-1{
    height: 40%;
    width:40%;
    margin-bottom: 10%;
    opacity: 5; 
    mix-blend-mode:luminosity; 

}
.char-1-hov{
    height: 55%;
    width:55%;
    margin-bottom: 13%;
}
/* .select-img img:nth-child(3){
    height: 40%;
    width:40%;
    margin-top: 10%;
    opacity: 5; 
    mix-blend-mode:luminosity; 
}
.select-img img:nth-child(4){
    height: 40%;
    width:40%;
    margin-top: 13%;
    opacity: 5; 
    mix-blend-mode:luminosity; 
}
.select-img img:nth-child(5){
    height: 40%;
    width:40%;
    margin-top: 13%;
    opacity: 5; 
    mix-blend-mode:luminosity; 
}
.select-img img:nth-child(6){
    height: 40%;
    width:40%;
    margin-top: 13%;
    opacity: 5; 
    mix-blend-mode:luminosity; 
} */

.charimage img{
    height: 80%;
    width: 80%;
}
.char-details{
    width: 50%;
    color: #FFFFFF;
}
.char-details h1{
    font-size:35px;
    font-family: 'Poppins', sans-serif;
}
.char-details p{
    font-size:22px;
    margin-top: -3%;
}
.char-btn{
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 2%;
    padding-bottom: 2%;
    /* background-color: #a53a80; */
    font-family: 'Poppins', sans-serif;
    background-color: transparent;
    /* font-size: 100%; */
    border: 1px solid white;
    color: white;
    font-weight: 800;
    letter-spacing: 1px;
    border-radius: 5px;
    width: 40%;
    height:15%;
    font-size:15px;
    text-align: center;
    cursor: pointer;
}

/* .newlines{
    position: absolute;
    margin-left:-54% ;
    margin-top: -7%;
    height: 30%;
    width: 30%;
    object-fit: contain;
    
} */




@media screen and (max-width: 768px){
    .char-page{
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
radial-gradient(155.35% 70.32% at 62.92% 6.07%, rgba(17, 212, 255, 0.5) 0%, rgba(117, 8, 184, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
radial-gradient(276.9% 100% at 98.44% 100%, #F95867 0%, #7508B8 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
       height: 100vh;
    }
    @media screen and (max-width: 375px){
        .char-page{
            height: 130vh;
        }
    }
    .select-img{
        cursor: auto;
    }
    .char-details-div{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .char-heading h1{
        font-size: 40px;
    }
    .char-heading p{
        width: 90%;
        margin-left: 5%;
        font-weight: 100;
        font-size: small;
    }
    .charimage{
        margin-left: 20%;
        height: 120%;
        width: 120%;
    }
    .char-details{
        margin-left: -3%;
        width: 97%;
    }
    .char-details h1{
        font-size: 30px;
    }
    .char-details p{
        font-size:15px ;
    }
    .select-img{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 30%;
       
    }
    .select-img img:nth-child(1){
        transform: rotate(-90deg); /* Rotate the image by 45 degrees */
        margin-right: 10%;
        margin-bottom: 10%;
    }
    .select-img img:nth-child(7){
        transform: rotate(-90deg);
        margin-left: 10%;
        margin-top: -12%;
    }
    .select-img img:nth-child(2){
        margin-right: 10%;
    }
    .select-img img:nth-child(3){
        margin-right: 10%;
    }
    .select-img img:nth-child(4){
        margin-right: 10%;
    }
    .select-img img:nth-child(5){
        margin-right: 10%;
    }
    
}