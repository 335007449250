@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Bruno+Ace+SC&family=Nunito+Sans:wght@300&family=Sigmar&family=Vina+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,600&display=swap');
.home{
    background: url(../../Asserts/Backurl.png);
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    /* width: 98.5vw;
    height: 145vh; */
   
    width: 100%;
    background-repeat: no-repeat; /* Prevent image repetition */
    background-size: cover; /* Stretch the image to cover the entire element */
    

}
.home-div-right{
    
    width:45.5%;
    /* background-color: red; */
}
.home-div-left{
    position: relative;
    width: 55%;
    
}
.char-img{
    
    width: 100%;
}
.char-img2{
    display: none;
}
/* .right-content{
   height: 100%;
   
} */

  .home-h1{
    color: #FFFFFF;
    font-style: normal;
    font-weight: 900;
    /* font-size: 450%; */
    /* line-height: 140%; */
    margin-left: 15%;
    margin-top: 22%;
    letter-spacing: 1px;
    font-family: 'Archivo Black', sans-serif;
    display: inline-block;
    
    width: 120%;
    font-size: 450%;
    /* height: 100%; */
  }
  .home-p{
    color:  #EAECF0;
    font-family: 'Poppins', sans-serif;
    font-style: italic;
    margin-left: 15%;
    margin-top: -7%;
    font-size: 120%;
    /* font-weight: 300; */
    width: 100%;
    /* height: 100%; */
  }
  .btn-div{
    margin-left: 15%;
    display: flex;
    justify-content: space-between;
    width:80%;
    /* height: 100%; */
  }
  .btn-div button:nth-child(1){
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 3%;
    padding-bottom: 3%;
    /* background-color: #a53a80; */
    font-family: 'Poppins', sans-serif;
    background-color: transparent;
    font-size: 100%;
    border: 1px solid white;
    color: white;
    font-weight: 800;
    letter-spacing: 1px;
    border-radius: 5px;
    width: 40%;
    height:20%;
    /* height: 100%; */
  }
  .btn-div img{
    width: 45%;
    height:22%;
    cursor: pointer;
  }
  .home-botdivs{
    display: flex;
    margin-left: 15%;
    margin-top: 8%;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-family: 'Archivo Black', sans-serif;
  }
  .home-botdivs h2{
    color: #FFFFFF;
    font-weight: 400;
    font-size: 250%;
  }
  .home-botdivs p{
    color: #FFFFFF;
    text-align: center;
    margin-top: -20%;
    font-size: 110%;
    font-weight: 100;
    letter-spacing: 1px ;
  } 
@media screen and (max-width: 1200px){
    .home-h1{
        font-size: 50px;
    }
    .btn-div button:nth-child(1){
       font-size: smaller;
    }
    .home-botdivs{
       
       font-size: x-small;
    }
} 
/* @media screen and (min-width: 1400px){

} */


/* mobile */
@media screen and (max-width: 768px){
    .home{
        display: flex;
        flex-direction: column;
       height: 180%;
       /* background: url(../../Asserts/MobileBackURL.svg); */
       background: none;
       background: url(../../Asserts/MobileBackURLNEW.png);
       background-repeat: no-repeat; /* Prevent image repetition */
       background-size:cover; /* Stretch the image to cover the entire element */
      
    }
    .home-div-right{
        width: 100%;
       height: 200%;
        display: grid;
       place-items: center;
       /* background: url(../../Asserts/TopBackurl.svg); */
       
    }
    .home-h1{
        width: 100%;
        font-size: 35px;
        margin-left: 10%;
        margin-top: 5%;
    }
    .home-p{
        width: 85%;
       margin-left: 10%;
    }
    .btn-div{
        margin-left:1%;
    }
    .home-botdivs{
        margin-left: 8%;
        width: 85%;
    }
    .home-div-left{
       
        width: 100%;
        /* background: url(../../Asserts/BottomBackurl.svg); */
        background-repeat: no-repeat; /* Prevent image repetition */
    background-size: cover; /* Stretch the image to cover the entire element */
    }
    .char-img{
        display: none;
    }
    .char-img2{
        display: block;
        height: 100%;
        width: 100%;
    }
        
}