@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

.nav-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10vh;
    width: 90vw;
    margin-left: 5%;
}
.nav-li{
    display: flex;
    width: 30vw;
    justify-content: space-between;
    align-items: center;
    color: #667085;
    
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    cursor: pointer;
}
.nav-li:hover{
    color: #6941C6;
}
.nav-li h5:nth-child(4){
    padding-left: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-right: 2%;
    border-radius: 5px;
    border:1px solid #667085;
}
.nav-li h5:nth-child(5){
    padding-left: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-right: 2%;
    border-radius: 5px;
    background-color: #7F56D9;
    color: white;
    border:1px solid #667085;
}
/* .nav-img{
   height: 15%;
   width: 20%;
} */
.nav-donut{
    display: none;
}
@media screen and (max-width: 768px){
    .nav-li{
        display: none;
    }
    .nav-div{
        height: 5vh;
        width: 90vw;
        margin-top: 5%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .nav-donut{
        display: block;
        /* position: absolute;
        right: 5%; */
        animation: App-logo-spin infinite 1s linear;
    }
}