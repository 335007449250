@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Bruno+Ace+SC&family=Nunito+Sans:wght@300&family=Sigmar&family=Vina+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,100&display=swap');
.footer{
    background-color: #101828;
    height: 50vh;
    width: 100vw;
}
.foot-img-div{
    
    height: 70%;
    width: 80%;
    margin-left: 10%;
    border-bottom: 1px solid white;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.foot-img-div img{
    height: 10%;
    width: 20%;
}
.foot-img-div p{
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-size: 100;
}
.foot-end{
    color: #e2e3e4;
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-left: 10%;
    font-size: medium;
}
@media screen and (max-width: 768px){
    .footer{
        height: 50vh;
    }
    .foot-img-div{
        height: 50%;
    }
    .foot-img-div{
        flex-direction: column;
        justify-content: center;
        align-items: start;
    }
    .foot-img-div img{
        height: 10%;
        width: 70%;
    }
    .foot-end{
        flex-direction: column-reverse;
    }
}