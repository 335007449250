@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Bruno+Ace+SC&family=Nunito+Sans:wght@300&family=Sigmar&family=Vina+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,100&display=swap');

.partners{
    background-color: #F2F4F7;
    /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
radial-gradient(60.4% 100% at 40.9% 100%, rgba(0, 179, 214, 0.5) 0%, rgba(117, 8, 184, 0) 100%),
radial-gradient(98.44% 182.15% at 98.44% 100%, #F95867 0%, #7508B8 100%); */
    width: 100vw;
    height: 80vh;
    display: flex;
    flex-direction: column;
}
.part-top{
    height: 50%;
    text-align: center;
}
.part-top h1{
    font-size: 350%;
    font-family: 'Archivo Black', sans-serif;
}
.part-top p{
    font-family: 'Poppins', sans-serif;
    width: 50%;
    font-size: 110%;
    margin-left: 25%;
    margin-top: -2%;
    color: #667085;
}
.part-down{
    height: 50%;
}
.img-div-1{
    display: flex;
    justify-content: space-between;
    width: 76%;
    margin-left: 13%;
}
.img-div-2{
    display: flex;
    justify-content: space-between;
    width: 45%;
    margin-top: 3%;
    margin-left: 27%;
}
.img-div-2 img{
    object-fit:contain;
    cursor: pointer;
    filter: brightness(-100);
   
}
.mobile-img{
    display: none;
}
@media screen and (max-width: 768px){
    .part-top{
        height: 30%;
    }
    .part-down{
       display: none;
    }
    .part-top h1{
        font-size: 300%;
    }
    .part-top p{
        font-size: 80%;
        width: 90%;
        margin-left: 5%;
    }
    .mobile-img{
        display: grid;
        grid-template-columns: 1fr;
        width: 90%;
        margin-left: 16%;
        margin-top: 10%;
        height: 100%;
    }
    .mobile-img img{
        height: 45%;
        width: 45%;
    }
    .logo-img1{
        display: flex;
        justify-content:space-between;
        width: 60vw;
    }
    .logo-img3{
        display: flex;
        justify-content:space-between;
        width: 70vw;
        margin-left: -5%;
    }
    .logo-img4{
        display: flex;
        justify-content:space-between;
        width: 70vw;
        margin-left: -5%;
    }
    .logo-img4-new{
        display: flex;
        flex-direction: column;
        margin-left: 5%;
        margin-top: 10%;
    }
    .logo-img4-new img:nth-child(1){
        height: 13%;
        width: 60%;
        object-fit:contain;
        cursor: pointer;
        filter: brightness(-10);
    }
    .logo-img4-new img:nth-child(2){
        height: 13%;
        width: 50%;
        margin-top: 30%;
        object-fit:contain;
        cursor: pointer;
        filter: brightness(-10);
    }
    .logo-img9{
        display: flex;
        justify-content: start;
        margin-left: 7%;

    }
}