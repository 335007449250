@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Bruno+Ace+SC&family=Nunito+Sans:wght@300&family=Sigmar&family=Vina+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,100&display=swap');

.exp{
   background-color: #F2F4F7;
   height: 100vh;
   width: 100vw;
   display: flex;
   flex-direction: column;
   
}
.exp-top{
    height: 40%;
    text-align: center;
}
.exp-top h1{
    font-size: 350%;
    font-family: 'Archivo Black', sans-serif;
}
.exp-top p{
    font-family: 'Poppins', sans-serif;
    width: 45%;
    font-size: 110%;
    margin-left: 25%;
    margin-top: -2%;
    color: #667085;
}
.exp-down{
    height: 60%;
}
.exp-video{
    height: 90%;
    width: 47%;
    background-color: rgb(216, 216, 216);
    margin-left: 25%;
    border-radius: 20px;
}
@media screen and (max-width: 768px){
    .exp-top{
        height: 50%;
        
    }
    .exp-down{
        height: 50%;
    }
    .exp-top h1{
        font-size: 40px;
    }
    .exp-top p{
        font-size: 15px;
        width: 94%;
        margin-left: 3%;
        margin-bottom: 10%;
    }
    .exp-video{
        height: 65%;
    width: 90%;
    margin-left: 5%;
    }

}