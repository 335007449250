/* .contents{
    width: 98vw;
    height: 28vh;
    display: grid;
    place-content: center;
} */
.contents{
    background-color: #F2F4F7;
}
.logo-div{
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* width: 80vw; */
    height: 25vh;
    width: 90vw;
    margin-left: 7vw;
    background-color: #F2F4F7;
}
.logo-div img:nth-child(1){
    /* height: 24%;
    width: 16%; */
    object-fit:contain;
    cursor: pointer;
    filter: brightness(-100);
    mix-blend-mode:luminosity; 
}
.logo-div img:nth-child(2){
    /* height: 24%;
    width: 11%; */
    object-fit:contain;
    cursor: pointer;
    filter: brightness(-10);
}
.logo-div img:nth-child(3){
    /* height: 24%;
    width: 19%; */
    object-fit:contain;
    cursor: pointer;
    filter: brightness(-100);
}
.logo-div img:nth-child(4){
    /* height: 24%;
    width: 8%; */
    object-fit:contain;
    cursor: pointer;
    filter: brightness(-100);
}
.logo-div img:nth-child(5){
    /* height: 24%;
    width: 7%; */
    object-fit:contain;
    cursor: pointer;
    filter: brightness(-100);
}
@media screen and (max-width: 1200px){
    /* .logo-div img{
        height: 30%;
        width: 18%;
        object-fit:fill;
    } */
}
@media screen and (max-width: 768px){
    .logo-div{
        display: flex;
        flex-direction: column;
        height: 70vh;
        align-items: center;
        justify-content: center;
    }
    .logo-div img{
        height: 19px;
        width:131px ;
        margin-top: 12px;
    }
    .logo-div img:nth-child(1){
        height: 8%;
        width: 60%;
        object-fit:contain;
        cursor: pointer;
        filter: brightness(-10);
        margin-top: 20%;
    }
    .logo-div img:nth-child(2){
        height: 6%;
        width: 40%;
        object-fit:contain;
        cursor: pointer;
        filter: brightness(-10);
        margin-top: 10%;
    }
    .logo-div img:nth-child(3){
        height: 8%;
        width: 60%;
        object-fit:contain;
        cursor: pointer;
        filter: brightness(-10);
        margin-top: 10%;
    }
    .logo-div img:nth-child(4){
        height: 8%;
        width: 30%;
        object-fit:contain;
        cursor: pointer;
        filter: brightness(-10);
        margin-top: 10%;
    }
    .logo-div img:nth-child(5){
        height: 8%;
        width: 25%;
        object-fit:contain;
        cursor: pointer;
        filter: brightness(-10);
        margin-top: 10%;
        margin-bottom: 20%;
    }
}